import { getCookie } from './utils';
import { reportFormSubmissionError } from '../common/Sentry';
import { handleDefaultModal } from './utils';

const baseUrl = process.env.NODE_ENV === 'prod' ? 'https://admin.eventicious.ru' : 'https://admin-test.eventicious.ru';

const formsSelectors = '#demonstration-form, #demonstration-form-modal';
const loginSelectorForm = document.querySelector('#demonstration-form-modal-login');
const demonstrationFormsList = document.querySelectorAll(formsSelectors);

demonstrationFormsList.forEach((demonstrationForm) => initDemonstrationForm(demonstrationForm));
if (loginSelectorForm) initLoginModalForm();

function getEventName(modalType) {
  switch (modalType) {
  case 'get_calc_roi':
    return 'form_send_get_calc_roi';
  case 'get_price':
    return 'form_send_get_price';
  case 'get_demo':
    return 'form_send_get_demo';
  case 'get_free':
    return 'form_send_get_free';
  case 'get_offer':
    return 'form_send_get_offer';
  case 'get_presentation':
    return 'form_send_get_presentation';
  default:
    return 'form_send';
  }
}

function initLoginModalForm() {
  const companyField = loginSelectorForm.querySelector('[name=company]');
  const emailField = loginSelectorForm.querySelector('[name=email]');
  const submitButton = loginSelectorForm.querySelector('button[type=submit]');

  loginSelectorForm.addEventListener('submit', onSubmit);

  async function onSubmit(e) {
    e.preventDefault();
    companyField.disabled = true;
    emailField.disabled = true;
    submitButton.disabled = true;
    submitButton.classList.add('button--loading');

    const payload = {
      name: companyField.value,
      email: emailField.value,
      LandingType: 'Talentrocks',
      type: 'GetAccess',
      source: 'Лендинг Talent Rocks, RU [Запрос доступа]',
      confirm: 'true',
      time: getDate(),
      gcLid: getCookie('gcLid'),
      message: `\u200B\u200B\u200B`,
    };

    try {
      const answer = await fetch(`${baseUrl}/api/subscribe/request_demo_new`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (answer.status === 200) {
        loginSelectorForm.classList.add('success');
        loginSelectorForm.reset();
        // Отправка события в Яндекс.Метрику
        // Для отладки можно к URL страницы добавить URL-параметр _ym_debug=1
        const yaMetrikaCounterId = window.Ya?._metrika?.getCounters?.()[0]?.id;
        if (yaMetrikaCounterId) {
          window.ym?.(yaMetrikaCounterId, 'reachGoal', 'form_send_get_access', { 'url': window.location.href });
        }
      } else {
        throw new Error(answer);
      }
    } catch (error) {
      reportFormSubmissionError(
        [
          {
            name: 'response',
            data: error,
          },
          {
            name: 'payload',
            data: payload,
          },
        ],
        'ru',
      );

      handleDefaultModal('#error-modal');
    } finally {
      companyField.parentElement.classList.remove('error');
      emailField.parentElement.classList.remove('error');
      companyField.disabled = false;
      emailField.disabled = false;
      submitButton.disabled = false;
      submitButton.classList.remove('button--loading');
    }
  }
}

function initDemonstrationForm(demonstrationForm) {
  const nameField = demonstrationForm.querySelector('[name=name]');
  const emailField = demonstrationForm.querySelector('[name=email]');
  const phoneField = demonstrationForm.querySelector('[name=phone]');
  const messageField = demonstrationForm.querySelector('[name=message]');
  const modalHeaderInput = demonstrationForm.querySelector('input[name=modal-header]');
  const submitButton = demonstrationForm.querySelector('button[type=submit]');
  const modalTypeInput = demonstrationForm.querySelector('input[name=modal-type]');

  demonstrationForm.addEventListener('submit', onSubmit);

  async function onSubmit(e) {
    e.preventDefault();
    nameField.disabled = true;
    emailField.disabled = true;
    phoneField.disabled = true;
    messageField.disabled = true;
    submitButton.disabled = true;
    submitButton.classList.add('button--loading');

    // Для формы ROI
    const NE = document.querySelector('input[name="NE"]');
    const ES = document.querySelector('input[name="ES"]');
    const HC = document.querySelector('input[name="HC"]');
    const RR = document.querySelector('input[name="RR"]');
    const OH = document.querySelector('input[name="OH"]');
    const HR = document.querySelector('input[name="HR"]');

    let additionalFieldsMessage = '';

    if (NE && ES && HC && RR && OH && HR) {
      const NE_value = NE.value.replace(/\s+/g, '');
      const ES_value = ES.value.replace(/\s+/g, '');
      const HC_value = HC.value.replace(/\s+/g, '');
      const RR_value = RR.value.replace(/\s+/g, '');
      const OH_value = OH.value.replace(/\s+/g, '');
      const HR_value = HR.value.replace(/\s+/g, '');

      additionalFieldsMessage = `\n\nКоличество новых сотрудников: ${NE_value}\nСредняя зарплата: ${ES_value}\nСредняя цена найма сотрудника: ${HC_value}\nКоэффициент текучести кадров: ${RR_value}%\nКоличество рабочих часов HR на найм одного сотрудника: ${OH_value}\nЦена часа работы HR менеджера: ${HR_value}`;
    }

    const modalTarget = modalHeaderInput ? ` [${modalHeaderInput.value}]` : '';
    const modalType = modalTypeInput?.value;
    const eventName = getEventName(modalType);

    const payload = {
      name: nameField.value,
      email: emailField.value,
      phone: phoneField.value,
      LandingType: 'Talentrocks',
      source: 'Лендинг Talent Rocks, RU' + modalTarget,
      confirm: 'true',
      roistatVisitId: getCookie('roistat_visit'),
      time: getDate(),
      gcLid: getCookie('gcLid'),
      message: `${messageField.value}${additionalFieldsMessage}\u200B\u200B\u200B`,
    };

    try {
      const answer = await fetch(`${baseUrl}/api/subscribe/request_demo_new`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (answer.status === 200) {
        demonstrationForm.classList.add('success');
        demonstrationForm.reset();
        // Отправка события в Яндекс.Метрику
        // Для отладки можно к URL страницы добавить URL-параметр _ym_debug=1
        const yaMetrikaCounterId = window.Ya?._metrika?.getCounters?.()[0]?.id;
        if (yaMetrikaCounterId) {
          window.ym?.(yaMetrikaCounterId, 'reachGoal', eventName, { 'url': window.location.href });
        }
      } else {
        throw new Error(answer);
      }
    } catch (error) {
      reportFormSubmissionError(
        [
          {
            name: 'response',
            data: error,
          },
          {
            name: 'payload',
            data: payload,
          },
        ],
        'ru',
      );

      handleDefaultModal('#error-modal');
    } finally {
      nameField.parentElement.classList.remove('error');
      emailField.parentElement.classList.remove('error');
      phoneField.parentElement.classList.remove('error');
      nameField.disabled = false;
      emailField.disabled = false;
      phoneField.disabled = false;
      messageField.disabled = false;
      submitButton.disabled = false;
      submitButton.classList.remove('button--loading');
    }
  }
}

function getDate() {
  const d = new Date();

  const to2Digit = function (number) {
    return number < 10 ? '0' + number : number;
  };

  return `${d.getFullYear()}-${to2Digit(d.getMonth() + 1)}-${to2Digit(d.getDate())}, ${to2Digit(
    d.getHours(),
  )}:${to2Digit(d.getMinutes())}:${to2Digit(d.getSeconds())}`;
}
